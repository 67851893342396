import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, Attribute, ModelType } from './jsonapi.service';
import { environment } from '../../../environments/environment';

@JsonApiModelConfig({
  type: 'products'
})
export class ProductModel extends JsonApiModel {
  @Attribute()
  name: string;

  @Attribute()
  producer: string;

  @Attribute()
  p_type: string;

  @Attribute()
  sv_percentage: string;

  @Attribute()
  components: string[];

  @Attribute()
  'created-at': Date;

  @Attribute()
  'updated-at': Date;
}
const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    products: ProductModel
  }
}
@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class ProductService extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public getProduct(modelType,  id: string) {
    return this.getEntity(modelType, id);
  }
}