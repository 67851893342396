import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, Attribute, ModelType } from './jsonapi.service';
import { environment } from '../../../environments/environment';

@JsonApiModelConfig({
  type: 'report-items'
})
export class ReportItemModel extends JsonApiModel {
  @Attribute()
  report_type: string;
  
  @Attribute()
  report_name: string;
  
  @Attribute()
  report_id: number;
  
  @Attribute()
  report_create_date: Date;
  
  @Attribute()
  report_update_date: Date;
  
  @Attribute()
  report_links: any;
  
  @Attribute()
  data_attributes: any;

  @Attribute()
  author: any;
  
  @Attribute()
  'created_at': Date;

  @Attribute()
  'updated_at': Date;
}
const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    reportItems: ReportItemModel
  }
}
@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class ReportItemService extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public getReportItem(modelType,  id: string) {
    return this.getEntity(modelType, id);
  }
}


@JsonApiModelConfig({
  type: 'application-logs'
})
export class ApplicationLogModel extends JsonApiModel {
  @Attribute()
  'created-at': Date;

  @Attribute()
  'updated-at': Date;
}
const configAppLog: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    applicationLogs: ApplicationLogModel
  }
}
@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(configAppLog)
export class ApplicationLogService extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public getZones(
    applicationLogId: string,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ApplicationLogModel, null, applicationLogId, null, null) + '/zones';
    const params = {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public closeRemarks(
    applicationLogId: string,
    remarksIds: string,
    headers?: HttpHeaders
  ) {
    let body = {
      'remarks_ids': remarksIds
    };
    const url: string = this.buildUrl(ApplicationLogModel, null, applicationLogId, null, null) + '/close-remarks';
    const params = {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });
    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

}