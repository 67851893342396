import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../../components/components.module';
import { AutosizeModule } from 'ngx-autosize';

import { HintModal } from './hint.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    AutosizeModule,
    TranslateModule
  ],
  declarations: [
    HintModal
  ],
  entryComponents: [
    HintModal
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HintModalModule {}
