import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.page.html',
  styleUrls: ['./hint.page.scss'],
})
export class HintModal implements OnInit {
  public hintMessage: string;
  public hintPictureUrl: string;
  public dismissLabel: string;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.hintMessage = this.navParams.get('hintMessage');
    this.hintPictureUrl = this.navParams.get('hintPictureUrl');;
    this.dismissLabel = this.navParams.get('dismissLabel');;
  }

  ngOnInit() {}

  async dismiss(data) {
    await this.modalController.dismiss({
      'dismissed': true,
      'data': data
    });
  }

  gotIt() {
    this.dismiss({});
  }
}
