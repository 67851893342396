export interface dataStatic {
    teamSummary: any;
    genericActivity: any;
    resourceDetail: any;
    dataResource: any;
}


/*  Mock static data for time report */
export const DATASTATIC: dataStatic = {
    teamSummary: [
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Mario Rossi",
            days: "5",
            dailyAverage: "8:00",
            lastActivity: "Primer",
            date: "Yesterday",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/23/07/31/scottish-fold-1057829_960_720.jpg",
            name: "Mario Rossi",
            days: "8",
            dailyAverage: "7:00",
            lastActivity: "Filling",
            date: "2 Mar 2020",
        },
        {
            photoUrl: "",
            name: "Mario Rossi",
            days: "5",
            dailyAverage: "8:00",
            lastActivity: "Primer",
            date: "5 Mar 2020",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Fabrizio Verdi",
            days: "7",
            dailyAverage: "8:00",
            lastActivity: "Primer",
            date: "Yesterday",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Mario Rossi",
            days: "6",
            dailyAverage: "5:00",
            lastActivity: "Primer",
            date: "Yesterday",
        },
        {
            photoUrl: "",
            name: "Mario Rossi",
            days: "5",
            dailyAverage: "4:00",
            lastActivity: "Primer",
            date: "Yesterday",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Mario Rossi",
            days: "5",
            dailyAverage: "8:00",
            lastActivity: "Primer",
            date: "Yesterday",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Mario Rossi",
            days: "5",
            dailyAverage: "8:00",
            lastActivity: "Primer",
            date: "Yesterday",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Mario Rossi",
            days: "5",
            dailyAverage: "8:00",
            lastActivity: "Primer",
            date: "Yesterday",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Mario Rossi",
            days: "5",
            dailyAverage: "8:00",
            lastActivity: "Primer",
            date: "Yesterday",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Mario Rossi",
            days: "5",
            dailyAverage: "8:00",
            lastActivity: "Primer",
            date: "Yesterday",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Mario Rossi",
            days: "5",
            dailyAverage: "8:00",
            lastActivity: "Primer",
            date: "Yesterday",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Mario Rossi",
            days: "5",
            dailyAverage: "8:00",
            lastActivity: "Primer",
            date: "Yesterday",
        },
    ],
    genericActivity: [
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Mario Rossi",
            hours: "8:00",
            activity: "Generic activity",
            zone: "Main deck",
            date: "Yesterday",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Anna Bianchi",
            hours: "5:00",
            activity: "Generic activity",
            zone: "Upper deck",
            date: "Yesterday",
        },
        {
            photoUrl: "",
            name: "Mario Rossi",
            hours: "8:00",
            activity: "Generic activity",
            zone: "Main deck",
            date: "8 Mar 2020",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Mario Rossi",
            hours: "8:00",
            activity: "Generic activity",
            zone: "Main deck",
            date: "2 Mar 2020",
        },
    ],
    resourceDetail: [
        {
            date: "Yesterday",
            hours: "08:00",
            activities: "Primer",
            zone: "Upper deck",
        },
        {
            date: "2 Mar 2020",
            hours: "07:00",
            activities: "Primer",
            zone: "Upper deck",
        },
        {
            date: "9 Mar 2020",
            hours: "05:00",
            activities: "Primer",
            zone: "Main deck",
        },
    ],
    dataResource: [
        {
            photoUrl:
                "",
            name: "Mario Rossi",
            isChecked: true,
            company: "Storm",
            costHourly: "18€",
            team: "Storm",
            shipyard: "Livorno",
            edit: "edit name",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Viola Verdi",
            isChecked: false,
            company: "Storm",
            costHourly: "18€",
            team: "Storm",
            shipyard: "Livorno",
            edit: "edit name",
        },
        {
            photoUrl:
                "https://cdn.pixabay.com/photo/2015/11/16/14/43/cat-1045782_960_720.jpg",
            name: "Tutti Colori",
            isChecked: true,
            company: "Storm",
            costHourly: "16€",
            team: "Storm",
            shipyard: "Pisa",
            edit: "edit name",
        },
    ],
};
