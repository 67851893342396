import { Injectable } from '@angular/core';
import { TaskService , TaskModel} from './tasks.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AddPointService extends TaskService {

    constructor(http: HttpClient) { super(http); }

    public getTasksFiltered(filters,
      headers?: HttpHeaders ) {
      const params = {  filter: filters  };
      const url: string = this.buildUrl(TaskModel, null, null, null, null);
      const requestOptions: object = this.buildRequestOptions({ headers, params, observe: 'response' });

      return this.http.get(url, requestOptions)
          .pipe(
            map((res: any) => {
                return this.extractCollectionData(res, TaskModel);
            }),
            catchError((res: any) => this.handleError(res))
          );
      }

    public getTaskInterventType(intervent_type_id, headers?: HttpHeaders ) {

    }
}
